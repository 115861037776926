import { CloseCircleOutlined } from '@ant-design/icons';
import { Checkbox, Image, Select, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState, useEffect } from 'react';
import useApiRequest, { roomOptions } from '../../utils/helperFunctions';
import { useNavigate } from 'react-router-dom';

interface PhotoDetailsProps {
  imageSrc: string;
  roomInfo?: any;
  asInfo?: string[];
  onClose?: () => void;
  onDescriptionChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  description?: string;
  moreDetail?: boolean;
  setAddtionalInfo?: any;
  onDetailsChange?: (details: { room: string | undefined; items: string[]; services: string[]; additionalItems: string }) => void;
}

const serviceCheckbox: any = [
  {
    label: "Basic image editing - $1.50 extra",
    value: 1.50,
  },
  {
    label: "Clutter removal - $8.00 extra",
    value: 8.00,
  },
  {
    label: "Occupied to vacant - $15.00 extra",
    value: 15.00,
  },
  {
    label: "Image modification - $16.00 extra",
    value: 16.00,
  }
];

type Service = {
  name: string;
  value: string;
};

const PhotoDetails: React.FC<PhotoDetailsProps> = ({ imageSrc, onClose, onDescriptionChange, roomInfo, description, moreDetail = false, onDetailsChange, asInfo, setAddtionalInfo }) => {
  const [selectedRoom, setSelectedRoom] = useState<string | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [additionalItems, setAdditionalItems] = useState<string>('');
  const [itemcheckbox, setCheckbox] = useState([]);
  const [serviceCheckboxOptions, setServiceCheckboxOptions] = useState<any>([]);
  const { apiRequest } = useApiRequest();
  const [additionalService, setAdditonalService] = useState<Service[]>([]);

  useEffect(() => {
    if (asInfo) {
      const asInfoMap = asInfo?.length > 0 && asInfo?.map((e: any) => ({
        label: e.as_name + ' - ' + parseFloat(e.as_price).toFixed(2) + ' $',
        value: e.as_price, // Use the unique ID as value
        key: e.as_id
      }));
      setServiceCheckboxOptions(asInfoMap);
    }
  }, [asInfo]);

  console.log({selectedServices})

  useEffect(() => {
    if (onDetailsChange) {
      onDetailsChange({
        room: selectedRoom,
        items: selectedItems,
        services: selectedServices,
        additionalItems,
      });
    }
  }, [selectedRoom, selectedItems, selectedServices, additionalItems]);

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      display: 'flex',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      maxWidth: `${moreDetail ? '100%' : '900px'}`,
      width: '100%',
      margin: '10px 0',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    imgBox: {
      minWidth: '280px',
      minHeight: '160px',
      maxWidth: '350px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageColumn: {
      position: 'relative',
      flex: '1',
      marginRight: '20px',
      maxWidth: '350px',
      width: '100%',
    },
    image: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
    },
    detailsColumn: {
      flex: '2',
      display: 'flex',
      flexDirection: 'column',
    },
    textHeader: {
      fontSize: '16px',
      marginBottom: '10px',
    },
    textArea: {
      flex: '1',
      padding: '10px',
      fontSize: '14px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      resize: 'none',
      minHeight: '200px',
    },
  };

  const handleRoomChange = (value: string) => {
    if (value) {
      const selectedObj = roomInfo?.find((r: any) => r.id === value);
      if (selectedObj) {
        const selectedData = selectedObj?.items?.map((e: any) => ({ id: e.id, name: e.name }));
        setCheckbox(selectedData);
      }
    }
    setSelectedRoom(value);
  };

  const handleItemsChange = (checkedValues: string[]) => {
    console.log(checkedValues)  
    setSelectedItems(checkedValues);
  };

  const handleServiceCheckboxChange = (checked: boolean, value: string, name: string, key:any) => {
    if (checked) {
      console.log("keyyyyyyyyyyy", key);
      
      setSelectedServices((prevSelectedServices) => [...prevSelectedServices, key]);
  
      const obj = {
        name,
        value,
      };
  
      // Fix: Adding a single object to the state array
      setAddtionalInfo((prev:any) => [...prev, obj]);
    } else {
      setSelectedServices((prevSelectedServices) =>
        prevSelectedServices.filter((service) => service !== key)
      );

      setAddtionalInfo((prev:any) => prev.filter((service:any) => service?.value !== value))
    }
  };
  

  const handleAdditionalItemsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalItems(e.target.value);
  };

  const getRoomIds = async () => {
    try {
      const resp = await apiRequest({
        url: 'rooms',
        method: 'GET',
      });
    } catch (error) {
      console.error('Error fetching room options:', error);
    }
  };

  const getAdditonalServices = async () => {
    try {
      const resp = await apiRequest({
        url: 'AdditionalServices',
        method: 'GET',
      });

    } catch (error) {
      console.error('Error fetching room options:', error);
    }
  };

  useEffect(() => {
    getRoomIds();
    getAdditonalServices();
  }, []);

  console.log("itemcheckbox", itemcheckbox)
  console.log(additionalService);

  return (
    <>
      <div style={styles.container}>
        <div style={styles.imageColumn}>
          <div style={styles.imgBox}>
            {imageSrc ? <Image src={imageSrc} alt="Preview" style={styles.image} /> : <Spin />}
          </div>
          <button onClick={onClose} style={styles.closeButton}><CloseCircleOutlined color='white' /></button>
        </div>
        {!moreDetail && (
          <div className='flex-fill'>
            <div className='only-desc' style={styles.textHeader}>Additional Information (Optional)</div>
            <textarea
              placeholder="Have something on your mind? Let us know..."
              style={styles.textArea}
              value={description}
              className='w-100'
              onChange={onDescriptionChange}
            />
          </div>
        )}
        {moreDetail && (
          <>
            <div className='flex-fill d-flex'>
              <div className="box-left w-50 p-3">
                <div className='moreDetail-box d-flex' style={styles.textHeader}>More Details (Optional)</div>
                <div className='dropdowns'>
                  <div>Room/Area*</div>
                  <Select
                    showSearch
                    placeholder="Select room/area"
                    style={{ width: '100%' }}
                    value={selectedRoom}
                    onChange={handleRoomChange}
                  >
                    {roomInfo?.map((room: any) => (
                      <Select.Option key={room.id} value={room.id}>{room.name}</Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="item-checkbox pt-4">
                  <span>Would you like any of these items in your room?</span>
                  <span className='d-block mb-2'>( Basic items will be added )</span>
                  <div className='add-services-checkbox'>
                    <Checkbox.Group value={selectedItems} onChange={handleItemsChange}>
                      {itemcheckbox?.map((item: any) => (
                        <Checkbox name={item?.name} style={{ width: '49%' }} key={item?.id} value={item?.id}>
                          {item?.name}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                </div>
                <div className='mt-3'>
                  <span>Not mentioned? Type them in here</span>
                  <TextArea
                    placeholder='Study table, dresser, recliner, carpets. etc.'
                    value={additionalItems}
                    onChange={handleAdditionalItemsChange}
                  />
                </div>
              </div>
              <div className="box-right w-50 p-3">
                <p>Additional services</p>
                <div>
                  {serviceCheckboxOptions.map((service: any) => (
                    <div key={service.key}>
                      <Checkbox
                        name={service?.label}
                        checked={selectedServices.includes(service.key)}
                        onChange={(e) => handleServiceCheckboxChange(e.target.checked, service.value, service?.label, service?.key)}
                      >
                        {service.label}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PhotoDetails;
