import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Tabs } from "antd";
import type { TabsProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

const { Item: MenuItem } = Menu;

interface MenuItemType {
  key: string;
  label: string;
  link?: string;
}

interface MenuGroupType {
  key: string;
  label: string;
  children: MenuItemType[];
}

const BottomNav: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
  const location = useLocation();
  const navigate = useNavigate();

  // Define the main tab items with their respective menus
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <Link to="/services/residential/">RESIDENTIAL</Link>,
      children: (
        <Menu
          mode="horizontal"
          style={{
            justifyContent: "space-around",
            maxWidth: "1266px",
            width: "100%",
            margin: "0 auto !important",
          }}
          selectedKeys={[location.pathname]}
        >
          <MenuItem key="/services/residential/virtual-staging">
            <Link to="/services/residential/virtual-staging">Virtual Staging</Link>
          </MenuItem>
          <MenuItem key="/services/residential/virtual-renovation">
            <Link to="/services/residential/virtual-renovation">Virtual Renovation</Link>
          </MenuItem>
          <MenuItem key="/services/residential/floor-plan">
            <Link to="/services/residential/floor-plan">Floor Plan</Link>
          </MenuItem>
          <MenuItem key="/services/residential/day-to-dusk">
            <Link to="/services/residential/day-to-dusk">Day to Dusk</Link>
          </MenuItem>
          <MenuItem key="/services/residential/object-removal">
            <Link to="/services/residential/object-removal">Object Removal</Link>
          </MenuItem>
          <MenuItem key="/services/residential/residential-image-enhancement">
            <Link to="/services/residential/residential-image-enhancement">Image Enhancement</Link>
          </MenuItem>
          <MenuItem key="/services/residential/occupied-to-vacant">
            <Link to="/services/residential/occupied-to-vacant">Occupied To Vacant</Link>
          </MenuItem>
        </Menu>
      ),
    },
    {
      key: "2",
      label: <Link to="/services/commercial/">COMMERCIAL</Link>,
      children: (
        <Menu
          mode="horizontal"
          style={{
            justifyContent: "space-around",
            maxWidth: "1266px",
            width: "100%",
            margin: "0 auto !important",
          }}
          selectedKeys={[location.pathname]}
        >
          <MenuItem key="/services/commercial/virtual-staging">
            <Link to="/services/commercial/virtual-staging">Commercial Virtual Staging</Link>
          </MenuItem>
          <MenuItem key="/services/commercial/commercial-renovation">
            <Link to="/services/commercial/commercial-renovation">Commercial Renovation</Link>
          </MenuItem>
          <MenuItem key="/services/commercial/day-to-dusk">
            <Link to="/services/commercial/day-to-dusk">Day to Dusk</Link>
          </MenuItem>
          <MenuItem key="/services/commercial/object-removal">
            <Link to="/services/commercial/object-removal">Object/Item Removal</Link>
          </MenuItem>
          <MenuItem key="/services/commercial/image-enhancement">
            <Link to="/services/commercial/image-enhancement">Image Enhancement</Link>
          </MenuItem>
          <MenuItem key="/services/commercial/occupied-to-vacant">
            <Link to="/services/commercial/occupied-to-vacant">Occupied to Vacant</Link>
          </MenuItem>
        </Menu>
      ),
    },
    {
      key: "3",
      label: <Link to="/services/graphic-designing/">GRAPHIC DESIGNING</Link>,
      children: (
        <Menu
          mode="horizontal"
          style={{
            justifyContent: "space-around",
            maxWidth: "1266px",
            width: "100%",
            margin: "0 auto !important",
          }}
          selectedKeys={[location.pathname]}
        >
          {/* <MenuItem key="/services/graphic-designing/business-card-design">
            <Link to="/services/graphic-designing/business-card-design">Business Card Designing</Link>
          </MenuItem>
          <MenuItem key="/services/graphic-designing/poster-designing">
            <Link to="/services/graphic-designing/poster-designing">Poster Designing</Link>
          </MenuItem>
          <MenuItem key="/services/graphic-designing/vector-design">
            <Link to="/services/graphic-designing/vector-design">Vector Design</Link>
          </MenuItem>
          <MenuItem key="/services/graphic-designing/digital-display-design">
            <Link to="/services/graphic-designing/digital-display-design">Digital Display Design</Link>
          </MenuItem> */}

          <MenuItem key="#graphic-design-section">
            <a href="#graphic-design-section">Business Card Designing</a>
          </MenuItem>
          <MenuItem key="#graphic-design-section">
            <a href="#graphic-design-section">Poster Designing</a>
          </MenuItem>
          <MenuItem key="#graphic-design-section">
            <a href="#graphic-design-section">Vector Design</a>
          </MenuItem>
          <MenuItem key="#graphic-design-section">
            <a href="#graphic-design-section">Digital Display Design</a>
          </MenuItem>
        </Menu>
      ),
    },
    {
      key: "4",
      label: <Link to="/services/photo-editing/">PHOTO EDITING</Link>,
      children: (
        <Menu
          mode="horizontal"
          style={{
            justifyContent: "space-around",
            maxWidth: "1266px",
            width: "100%",
            margin: "0 auto !important",
          }}
          selectedKeys={[location.pathname]}
        >
          <MenuItem key="/services/pe/image-enhancement">
            <Link to="/services/pe/image-enhancement">Image Enhancement</Link>
          </MenuItem>
          <MenuItem key="/services/pe/day-to-dusk">
            <Link to="/services/pe/day-to-dusk">Day to Dusk</Link>
          </MenuItem>
          <MenuItem key="/services/pe/object-removal">
            <Link to="/services/pe/object-removal">Object Removal</Link>
          </MenuItem>
          <MenuItem key="/services/pe/occupied-to-vacant">
            <Link to="/services/pe/occupied-to-vacant">Occupied To Vacant</Link>
          </MenuItem>
        </Menu>
      ),
    },
  ];

  // Function to determine the active tab based on the current path
  const matchTabKeyWithLocation = (): string => {
    if (location.pathname.startsWith("/services/residential/")) {
      return "1";
    } else if (location.pathname.startsWith("/services/commercial/")) {
      return "2";
    } else if (location.pathname.startsWith("/services/graphic-designing/")) {
      return "3";
    } else if (location.pathname.startsWith("/services/photo-editing/")) {
      return "4";
    }
    return "1"; // Default to the first tab if no match is found
  };

  const [activeKey, setActiveKey] = useState(matchTabKeyWithLocation());

  console.log("ActiveKEY", activeKey);

  // Update activeKey when the route changes
  useEffect(() => {
    const newActiveKey = matchTabKeyWithLocation();
    if (newActiveKey !== activeKey) {
      setActiveKey(newActiveKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // Handle window resize to toggle between mobile and desktop views
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to render dropdown menus for mobile view
  const renderMenu = (items: MenuItemType[]) => {
    return (
      <Menu selectedKeys={[location.pathname]}>
        {items.map((item) => (
          <MenuItem key={item.link}>
            <Link to={item.link || "#"}>{item.label}</Link>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  // Define dropdown menu items for mobile view
  const menuItems: MenuGroupType[] = [
    {
      key: "1",
      label: "RESIDENTIAL",
      children: [
        { key: "8", label: "Virtual Staging", link: "/services/residential/virtual-staging" },
        { key: "9", label: "Virtual Renovation", link: "/services/residential/virtual-renovation" },
        { key: "10", label: "Floor Plan", link: "/services/residential/floor-plan" },
        { key: "13", label: "Image Enhancement", link: "/services/residential/residential-image-enhancement" },
        { key: "14", label: "Photo Modification", link: "/services/photo-modification" },
      ],
    },
    {
      key: "2",
      label: "COMMERCIAL",
      children: [
        { key: "15", label: "Commercial Staging", link: "/services/commercial/virtual-staging" },
        { key: "16", label: "Commercial Renovation", link: "/services/commercial/commercial-renovation" },
        { key: "17", label: "Day to Dusk", link: "/services/commercial/day-to-dusk" },
        { key: "21", label: "Photo Modification", link: "/services/photo-modification" },
      ],
    },
    {
      key: "3",
      label: "GRAPHIC DESIGNING",
      children: [
        { key: "22", label: "Business Card Designing", link: "/services/graphic-designing/business-card-design" },
        { key: "23", label: "Poster Designing", link: "/services/graphic-designing/poster-designing" },
        { key: "24", label: "Vector design", link: "/services/graphic-designing/vector-design" },
        { key: "25", label: "Digital display design", link: "/services/graphic-designing/digital-display-design" },
      ],
    },
    {
      key: "4",
      label: "PHOTO EDITING",
      children: [
        { key: "29", label: "Occupied To Vacant", link: "/services/pe/occupied-to-vacant" },
        { key: "35", label: "Photo Modification", link: "/services/photo-modification" },
      ],
    },
  ];

  // Handle tab change by updating activeKey and navigating to the base route
  const handleTabChange = (key: string) => {
    setActiveKey(key);
    if (key === "1") {
      navigate("/services/residential/");
    } else if (key === "2") {
      navigate("/services/commercial/");
    } else if (key === "3") {
      navigate("/services/graphic-designing/");
    } else if (key === "4") {
      navigate("/services/photo-editing/");
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="main-header-dropdown">
          {menuItems.map((menuItem) => (
            <Dropdown key={menuItem.key} overlay={renderMenu(menuItem.children)}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {menuItem.label}
              </a>
            </Dropdown>
          ))}
        </div>
      ) : (
        <div className="main-header-tabs">
          <Tabs
            activeKey={activeKey}
            onChange={handleTabChange}
            items={items}
            centered
          />
        </div>
      )}
    </>
  );
};

export default BottomNav;
