import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import MainFooter from "./component/Layout/Footer";
import Navbar from "./component/Layout/Header";
import PEObjectRemoval from "./component/Pages/PEObjectRemoval";
import { ScrollToTop } from "./utils/helperFunctions";
import { LoadingOutlined } from "@ant-design/icons";
import VirtualStagingForm from "./component/Form/VirtualStagingForm";
import VirtualRenovationForm from "./component/Form/VirtualRenovationForm";
import FloorPlanForm from "./component/Form/FloorPlanForm";
import DaytoDuskForm from "./component/Form/DaytoDuskForm";
import ObjectRemovalForm from "./component/Form/ObjectRemoval";
import ImageEnhacementForm from "./component/Form/ImageEnhanceForm";
import OccupiedVacantForm from "./component/Form/OccupiedForm";
import BuisnessCardForm from "./component/Form/BuisnessCardForm";
import PosterDesignForm from "./component/Form/PosterDesignForm";
import VectorDesignForm from "./component/Form/VectorDesignForm";
import DigitalDisplayForm from "./component/Form/DigitalDisplayForm";
import ResidentialPage from "./component/Pages/Residential";
import CommercialPage from "./component/Pages/Commercial";
import GraphicDesignPage from "./component/Pages/GraphicDesigning";
import PhotoEditingPage from "./component/Pages/PhotoEditingPage";

// Lazy loading for pages
const Home = lazy(() => import("./component/Pages/Home/Home"));
const TryForFree = lazy(() => import("./component/Pages/TryForFree"));
const VirtualStaging = lazy(() => import("./component/Pages/VirtualStaging"));
const VirtualRenovation = lazy(
  () => import("./component/Pages/VirtualRenovation")
);
const FloorPlan = lazy(() => import("./component/Pages/FloorPlan"));
const DaytoDusk = lazy(() => import("./component/Pages/DaytoDusk"));
const ObjectRemoval = lazy(() => import("./component/Pages/ObjectRemoval"));
const Brightening = lazy(() => import("./component/Pages/Brightening"));
const CommercialStaging = lazy(
  () => import("./component/Pages/CommercialStaging")
);
const CommercialRenovation = lazy(
  () => import("./component/Pages/CommercialRenovation")
);
const PhotoEditing = lazy(() => import("./component/Pages/PhotoEditing"));
const LogoBranding = lazy(() => import("./component/Pages/LogoBranding"));
const VectorDesign = lazy(() => import("./component/Pages/VectorDesign"));
const DigitalDisplay = lazy(() => import("./component/Pages/DigitalDesign"));
const OccupiedVacant = lazy(() => import("./component/Pages/Occupied2Vacant"));
const OrderForm = lazy(() => import("./component/Form/OrderForm"));
const CommercialStagingForm = lazy(
  () => import("./component/Form/CommercialStagingForm")
);
const UserDashboard = lazy(
  () => import("./component/UserDashboard/DashboardSidebar")
);
const LoginSignup = lazy(
  () => import("./component/Pages/LoginSignup/LoginSignupComponent")
);
const Cart = lazy(() => import("./component/Pages/Cart"));
const NotFound = lazy(() => import("./component/Pages/Error404"));
const Testimonials = lazy(() => import("./component/Pages/Reviews"));
const BlogPage = lazy(() => import("./component/Pages/Blog"));
const Gallery = lazy(() => import("./component/Pages/Gallery"));
const PrintAds = lazy(() => import("./component/Pages/PrintAdvertizement"));
const ContactUs = lazy(() => import("./component/Pages/ContactUs"));
const TermsCon = lazy(() => import("./component/Pages/TermsCon"));
const PrivacyPolicy = lazy(() => import("./component/Pages/Privacy"));
const AboutUs = lazy(() => import("./component/Pages/AboutUS"));
const BulkQuotes = lazy(() => import("./component/Form/BulkQuotes"));
const Profile = lazy(() => import("./component/Pages/Profile"));
const PhotoEditingForm = lazy(
  () => import("./component/Form/PhotoEditingForm")
);
const Paypal = lazy(() => import("./component/Pages/MakePayment"));
const CommDaytoDusk = lazy(
  () => import("./component/Pages/CommercialDaytoDusk")
);
const CommObjectRemoval = lazy(
  () => import("./component/Pages/CommObjRemoval")
);
const CommOccupiedVacant = lazy(
  () => import("./component/Pages/CommOccVacant")
);
const PEOccupiedVacant = lazy(() => import("./component/Pages/PEOccVacant"));
const PEImageEnhacement = lazy(
  () => import("./component/Pages/ImageEnhancement")
);
const PEDaytoDusk = lazy(() => import("./component/Pages/PEDayToDusk"));

function App() {
  const [userDash, setUserDash] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      delay: 300,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  // Function to determine if header and footer should be shown
  const showHeaderFooter = () => {
    return userDash; // Show header and footer if userDash is false
  };

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Suspense
          fallback={
            <div
              style={{
                height: "98vh",
                display: "Flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOutlined
                style={{
                  fontSize: "55px",
                  color: "#32ae96",
                }}
              />
            </div>
          }
        >
          {showHeaderFooter() ? <Navbar /> : <Navbar static={true} />}
          <Routes>
            {/* General Pages */}
            <Route path="/" element={<Home setUserDash={setUserDash} />} />
            <Route
              path="/login"
              element={<LoginSignup />}
            />
            <Route path="/payment" element={<Paypal />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/about-us"
              element={<AboutUs />}
            />
            <Route path="/privacy-policy" element={<TermsCon />} />
            <Route path="/try-for-free" element={<TryForFree />} />
            {/* <Route
              path="/profile"
              element={<Profile />}
            /> */}

            {/* Services Pages */}
            <Route
              path="/services/residential/virtual-staging"
              element={<VirtualStaging />}
            />

            <Route path="/services/residential" element={<ResidentialPage />} />

            <Route path="/services/commercial" element={<CommercialPage />} />

            <Route path="/services/graphic-designing" element={<GraphicDesignPage />} />

            <Route path="/services/photo-editing" element={<PhotoEditingPage />} />

            <Route
              path="/services/residential/virtual-renovation"
              element={<VirtualRenovation />}
            />
            <Route
              path="/services/residential/floor-plan"
              element={<FloorPlan />}
            />

            <Route
              path="/services/residential/day-to-dusk"
              element={<DaytoDusk />}
            />
            <Route path="/services/pe/day-to-dusk" element={<PEDaytoDusk />} />
            <Route
              path="/services/commercial/day-to-dusk"
              element={<CommDaytoDusk />}
            />

            <Route
              path="/services/residential/object-removal"
              element={<ObjectRemoval />}
            />
            <Route
              path="/services/pe/object-removal"
              element={<PEObjectRemoval />}
            />
            <Route
              path="/services/commercial/object-removal"
              element={<CommObjectRemoval />}
            />

            <Route
              path="/services/commercial/virtual-staging"
              element={<CommercialStaging />}
            />
            <Route
              path="/services/commercial/commercial-renovation"
              element={<CommercialRenovation />}
            />

            <Route
              path="/services/commercial/occupied-to-vacant"
              element={<CommOccupiedVacant />}
            />
            <Route
              path="/services/residential/occupied-to-vacant"
              element={<OccupiedVacant />}
            />
            <Route
              path="/services/pe/occupied-to-vacant"
              element={<PEOccupiedVacant />}
            />

            <Route
              path="/services/pe/image-enhancement"
              element={<PEImageEnhacement />}
            />
            <Route
              path="/services/commercial/image-enhancement"
              element={<PEImageEnhacement />}
            />
            <Route
              path="/services/residential/residential-image-enhancement"
              element={<Brightening />}
            />

            <Route
              path="/services/graphic-designing/business-card-design"
              element={<LogoBranding />}
            />
            <Route
              path="/services/graphic-designing/vector-design"
              element={<VectorDesign />}
            />
            <Route
              path="/services/graphic-designing/digital-display-design"
              element={<DigitalDisplay />}
            />
            <Route
              path="/services/graphic-designing/poster-designing"
              element={<PrintAds />}
            />

            {/* Forms Pages */}
            <Route path="/services/order-page" element={<OrderForm />} />
            <Route
              path="/services/commercial/commercial-form"
              element={<CommercialStagingForm />}
            />

            <Route
              path="/services/virtual-staging-form"
              element={<VirtualStagingForm />}
            />

            <Route
              path="/services/virtual-renovation-form"
              element={<VirtualRenovationForm />}
            />

            <Route
              path="/services/floor-plan-form"
              element={<FloorPlanForm />}
            />

            <Route
              path="/services/day-to-dusk-form"
              element={<DaytoDuskForm />}
            />

            <Route
              path="/services/object-removal-form"
              element={<ObjectRemovalForm />}
            />

            <Route
              path="/services/image-enhancement-form"
              element={<ImageEnhacementForm />}
            />

            <Route
              path="/services/occupied-vacant-form"
              element={<OccupiedVacantForm />}
            />

            <Route
              path="/services/business-card-form"
              element={<BuisnessCardForm />}
            />

            <Route
              path="/services/poster-design-form"
              element={<PosterDesignForm />}
            />

            <Route
              path="/services/vector-design-form"
              element={<VectorDesignForm />}
            />

            <Route
              path="/services/digital-display-form"
              element={<DigitalDisplayForm />}
            />

            <Route path="/order-bulk-quotes" element={<BulkQuotes />} />
            <Route path="/photo-editing-form" element={<PhotoEditingForm />} />

            {/* Other Pages */}
            <Route path="/cart" element={<Cart />} />
            <Route path="/reviews" element={<Testimonials />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/gallery" element={<Gallery />} />

            {/* User Dashboard */}
            <Route
              path="/user-dashboard/*"
              element={<UserDashboard check={setUserDash} />}
            />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />

            {/* Terms and Conditions */}
            <Route path="/terms-and-conditions" element={<TermsCon />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          {showHeaderFooter() && <MainFooter />}
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
