const CaterIndustry = () => {
    return(
        <section className="industry-section radius-60">
            <div className="text-center mb-4" data-aos="fade-up">
                <h2 className="border-bottom d-inline-block">Your industry, our expertise!</h2>
            </div>
            <div className="row justify-content-center" data-aos="fade-up">
                <div className="col-md-6" data-aos="fade-up">
                    <div className="industry-box">
                        <img src="/assets/images/industry1.jpeg" alt="Industry-1"/>
                        <h3>broker/brokerage firm</h3>
                        {/* <p>We help listing agents and brokerages quickly, easily, and inexpensively edit and enhance
                            photos, remove clutter, virtually stage rooms, and organize images.</p> */}
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up">
                    <div className="industry-box">
                        <img src="/assets/images/industry2.jpg" alt="Industry-1"/>
                        <h3>Real Estate Photographer</h3>
                        {/* <p>We help you provide a consistent photography standard for all of your company’s listings by
                            automating image enhancement quickly, easily, and inexpensively.</p> */}
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up">
                    <div className="industry-box">
                        <img src="/assets/images/industry3.jpg" alt="Industry-1"/>
                        <h3>Real Estate Agent</h3>
                        {/* <p>We simplify the editing process for your listing photography, enhancing photos and
                            recommending changes and adjustments.</p> */}
                    </div>
                </div>
                <div className="col-md-6" data-aos="fade-up">
                    <div className="industry-box">
                        <img src="/assets/images/industry4.jpg" alt="Industry-1"/>
                        <h3>Developers/Home Builders</h3>
                        {/* <p>Virtual Homepix can help you provide more value to your membership by delivering a tool that
                            simplifies and improves their listing photography process.</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CaterIndustry;