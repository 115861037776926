import React, { useEffect, useState } from 'react';
import './slider.css';

const GallerySlider: React.FC<{images: string[]}> = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [touchStartPos, setTouchStartPos] = useState<number | null>(null);

    // Automatically change the slide every 4.5 seconds (only when images are present)
    useEffect(() => {
        if (images.length > 0) {
            const testimTimer = setTimeout(() => {
                playSlide(currentSlide + 1);
            }, 4500);

            return () => clearTimeout(testimTimer);
        }
    }, [currentSlide, images]);

    // Function to update the current slide index
    const playSlide = (slide: number) => {
        setCurrentSlide(slide < 0 ? images.length - 1 : slide % images.length);
    };

    // Handling the click on dots to jump to a specific slide
    const handleDotClick = (index: number) => {
        setCurrentSlide(index);
    };

    // Handling the click on arrows (next/previous slide)
    const handleArrowClick = (direction: 'left' | 'right') => {
        playSlide(direction === 'left' ? currentSlide - 1 : currentSlide + 1);
    };

    // Touch event handlers for mobile swipe navigation
    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStartPos(e.changedTouches[0].clientX);
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (!touchStartPos) return;
        const touchEndPos = e.changedTouches[0].clientX;
        const touchPosDiff = touchStartPos - touchEndPos;

        if (touchPosDiff > 30) {
            playSlide(currentSlide + 1); // Swipe left to go to the next slide
        } else if (touchPosDiff < -30) {
            playSlide(currentSlide - 1); // Swipe right to go to the previous slide
        }
        setTouchStartPos(null);
    };

    return (
        <section
            id="testim"
            className="testim gallerySlider"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className="testim-cover">
                <div className="wrap">
                    {/* Right Arrow */}
                    <span
                        id="right-arrow"
                        className="arrow right fa fa-chevron-right"
                        onClick={() => handleArrowClick('right')}
                    />
                    {/* Left Arrow */}
                    <span
                        id="left-arrow"
                        className="arrow left fa fa-chevron-left"
                        onClick={() => handleArrowClick('left')}
                    />
                    
                    {/* Dot Indicators */}
                    <ul id="testim-dots" className="dots">
                        {images.map((_, index) => (
                            <li
                                key={index}
                                className={index === currentSlide ? 'dot active' : 'dot'}
                                onClick={() => handleDotClick(index)}
                            />
                        ))}
                    </ul>

                    {/* Slide Content */}
                    <div id="testim-content" className="cont">
                        {images.map((imageSrc, index) => (
                            <div
                                key={index}
                                className={`img ${index === currentSlide ? 'active' : ''}`}
                            >
                                <img src={imageSrc} alt={`Slide ${index + 1}`} className="w-100" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GallerySlider;
