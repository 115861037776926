import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  Checkbox,
  Modal,
  CheckboxProps,
  message,
  Flex,
} from "antd";
import { ArrowRightOutlined, UploadOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./orderform.css";
import PhotoDetails from "./PhotoDetails";
import StepComponent from "../Sections/Steps";
import GallerySlider from "../Slider/GallerySlider";
import useApiRequest from "../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

const { Item } = Form;

const CommercialStagingForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    fileList: [] as any[],
    descriptions: [] as string[],
    selectedStyle: "",
    details: [] as any[], // Add this state to track details of each photo
    additionalService: [] as any[],
  });

  const [current, setCurrent] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const { apiRequest } = useApiRequest();
  const [roomResp, setRoomResp] = useState([]);
  const [asInfo, setAsInfo] = useState([]);
  const [addtionalInfo, setAddtionalInfo] = useState([]);
  const pricePerPhoto = 8;
  const [paymentModalSwitch, setPaymentModalSwitch] = useState(false)
  const navigate = useNavigate()
  const [modalVisible, setModalVisible] = useState(false);

  const designCardsData = [
    { 
        name: "Modern",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/main_photo_4-_1727560488.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Bedroom_glam_1727605673.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/bedroom2_k_-g_1727623767.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Dining_k-g_1_1727624905.jpg`
        ]
    },
    { 
        name: "Industrial", 
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/bedroom-01_indus_1727635720.jpg`, 
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/bedroom-02_1727635759.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/dining_1727635789.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/living_dinig_1727635832.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/living_1727635862.jpg`
        ] 
    },
    { 
        name: "Glam", 
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/livng_dining_1727635915.jpg`, 
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_bedroom-01_1727635951.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_bedroom-02_1727635976.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/living_1727635995.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/dining_1727636020.jpg`
        ] 
    },
    // Add more design card data as needed
  ];

  const [sliderImages, setSliderImages] = useState<string[]>([]);

  const steps = [
    {
      title: "Personal Info",
    },
    {
      title: "Select style",
    },
    {
      title: "Upload photos",
    },
    {
      title: "Photo details",
    },
    {
      title: "Add extra",
    },
  ];

  console.log(addtionalInfo);

  // useEffect(() => {
  //   updateStep();
  //   calculateTotalPrice();
  // }, [formData]);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([getRoomId(), getASservices()]);
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    calculateTotalPrice();
  }, [formData.fileList]);

  const getRoomId = async () => {
    try {
      const response = await apiRequest({
        url: "/rooms",
        method: "GET",
        showMessage: false,
      });
      if (response.status === 200) {
        setRoomResp(response.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  const getASservices = async () => {
    try {
      const response = await apiRequest({
        url: "/AdditionalServices",
        method: "GET",
        showMessage: false,
      });
      if (response.status === 200) {
        console.log("Additional Services", response.data);
        setAsInfo(response.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  const updateStep = () => {
    if (formData.name && formData.email && formData.phone) {
      if (formData.selectedStyle) {
        if (formData.fileList.length > 0) {
          if (formData.details.length === formData.fileList.length) {
            setCurrent(4); // Step 5: Add extra
          } else {
            setCurrent(3); // Step 4: Photo details
          }
        } else {
          setCurrent(2); // Step 3: Upload photos
        }
      } else {
        setCurrent(1); // Step 2: Select style
      }
    } else {
      setCurrent(0); // Step 1: Personal Info
    }
  };

  const calculateTotalPrice = () => {
    const newTotalPrice = formData.fileList.length * pricePerPhoto;
    setTotalPrice(newTotalPrice);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  const handleUploadChange = (fileList: any) => {
    setFormData((prevData) => ({
      ...prevData,
      fileList: fileList.fileList,
    }));
  };

  const handleClose = (index: number) => () => {
    setFormData((prevData) => {
      const newFileList = prevData.fileList.slice();
      newFileList.splice(index, 1);
      const newDescriptions = prevData.descriptions.slice();
      newDescriptions.splice(index, 1);
      const newDetails = prevData.details.slice();
      newDetails.splice(index, 1);
      return {
        ...prevData,
        fileList: newFileList,
        descriptions: newDescriptions,
        details: newDetails,
      };
    });
  };

  const handleDescriptionChange =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setFormData((prevData) => {
        const newDescriptions = [...prevData.descriptions];
        newDescriptions[index] = value;
        return {
          ...prevData,
          descriptions: newDescriptions,
        };
      });
    };

  const onCheckboxChange: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        user_details: {
          email: formData.email,
          phone: formData.phone,
          name: formData.name,
          team_id: "",
        },
        property_data: {
          order_name: formData.name,
          order_type: "pr",
          images: formData.fileList.map((file, index) => ({
            url: file.url || file.response?.file_url, // Ensure the URL is set correctly
            room_id: formData.details[index]?.room || null,
            additional_room_items:
              formData.details[index]?.additionalItems || "",
            room_item_id: formData.details[index]?.items || [],
            additional_services: formData.details[index]?.services || [],
          })),
        },
      };
      console.log("Form data to be sent:", payload);

      navigate("/payment")

      const resp = await apiRequest({
        url: "OrderCreate",
        method: "POST",
        payload: payload,
        showMessage: true,
      });

      console.log("RRESPPPP", resp);

      if (resp.status === 200 && resp.statusText === "OK") {
        message.success(resp?.data?.message.toUpperCase());
      }
    } catch (e) {
      console.error("Error ->", e);
    }
    // setPaymentModalSwitch((prev:boolean)=>{
    //   return !prev
    // })
  };

  const handleDetailsChange =
    (index: number) =>
    (details: {
      room: string | undefined;
      items: string[];
      services: string[];
      additionalItems: string;
    }) => {
      console.log("details.services", details.services);

      setFormData((prevData) => {
        const newDetails = [...prevData.details];
        newDetails[index] = details;
        return {
          ...prevData,
          details: newDetails,
        };
      });
    };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList.map((file: any) => ({
      ...file,
      url: file.response?.url || file.url, // Ensure the URL is captured
    }));
  };

  const handleDesignCardClick = (name: string, images: any) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedStyle: name,
    }));
  };

  const handleModalImages = (images: any) => {
    setSliderImages(images);
    setModalVisible(true);
  };

  const handleUploadMedia = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
  
      return await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust the token retrieval as needed
        },
        body: formData,
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    } catch(e){
      console.error("Error uploading media:", e);
    }
  };

  const sum = addtionalInfo.reduce((pv, cv:any) => pv + cv.value, 0)

  return (
    <>
      <div className="steps-container">
        <StepComponent current={current} steps={steps} />
      </div>
      <div className="order-form-container hp-container">
        <h2 className="mb-4 pt-4">Start by telling us about yourself</h2>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={10}>
            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Your name*"
                name="name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input
                  placeholder="For example; John Smith"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Item>
            </Col>

            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Email*"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                ]}
              >
                <Input
                  type="email"
                  placeholder="For example; johnsmith98@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Item>
            </Col>

            <Col sm={24} md={24} lg={12} xl={12}>
              <Item
                label="Phone number*"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
              >
                <PhoneInput
                  country={"in"}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{ width: "100%" }}
                />
              </Item>
            </Col>
          </Row>
          <hr />
          <h5>Pick a furnishing style for your property*</h5>
          <div className="row pt-4">
            {designCardsData.map((design, index) => (
              <div
                className={`col-md-2 col-sm-4 ${
                  design.name === formData.selectedStyle && "selected-style"
                }`}
                key={index}
              >
                <div
                  className={`design-card text-center p-2 ${
                    formData.selectedStyle === design.name ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleDesignCardClick(design.name, design.images)
                  }
                >
                  <div className="design-image">
                    <img
                      src={design.thumbnail}
                      alt="Design Image"
                      className="w-100"
                    />
                  </div>
                  <div className="design-text">
                    <span>{design.name}</span>
                  </div>
                  <div
                    className="see-examplebtn"
                    style={{
                      color: "navy",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                    onClick={() => handleModalImages(design.images)}
                  >
                    view example
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <Item
            label="Upload some photos here*"
            name="upload"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Please upload some photos!" }]}
          >
            <Upload
              listType="picture"
              // fileList={formData.fileList}
              onChange={handleUploadChange}
              // beforeUpload={() => false} // Prevent automatic upload
              multiple
              customRequest={({ file, onSuccess, onError }) => {
                handleUploadMedia(file).then(onSuccess).catch(onError);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload More Photos</Button>
            </Upload>
          </Item>

          {formData?.fileList.length > 0 && (
            <>
              <hr />

              <b>Add Details about your photos.</b>

              <div className="image-detail pt-4">
                {formData.fileList.map((file, index) => (
                  <PhotoDetails
                    asInfo={asInfo}
                    roomInfo={roomResp}
                    key={file.uid}
                    imageSrc={file?.response?.file_url}
                    onClose={handleClose(index)}
                    moreDetail={true}
                    onDetailsChange={handleDetailsChange(index)}
                    onDescriptionChange={handleDescriptionChange(index)}
                    description={formData.descriptions[index] || ""}
                    setAddtionalInfo={setAddtionalInfo}
                  />
                ))}
              </div>
            </>
          )}
          <hr />
          <div className="paymentBox">
            <div className="coupon-container">
              <label htmlFor="coupon" className="coupon-label">
                Enter a coupon code
              </label>
              <div className="coupon-input-container">
                <input
                  type="text"
                  id="coupon"
                  className="coupon-input"
                  placeholder="FOR EXAMPLE; HURRY100"
                />
                <button className="apply-button">APPLY</button>
              </div>
            </div>

            <div className="order-summary-container">
              <div className="order-summary">
                <h2>ORDER SUMMARY</h2>
                <div className="order-item w-100">
                  <span className="w-100">

                    {addtionalInfo.length > 0 && <b>Addtional Services</b>}
                    {addtionalInfo.length > 0 && <hr style={{marginBlock: '5px'}} />}
                    {addtionalInfo.length > 0 &&
                      addtionalInfo?.map((item: any) => <div className="d-flex justify-content-between">
                        <span>{item?.name.split('-')[0]}</span>
                        <b>{item?.name.split('-')[1]}</b>
                      </div>)
                    }
                  </span>
                </div>
                
                <Flex justify="space-between">
                <span>Per Image</span>
                <b>${totalPrice.toFixed(2)}</b>
                </Flex>

                <hr />
                <div className="order-total mt-0">
                  <span>ORDER TOTAL</span>
                  <span>${sum + totalPrice}</span>
                </div>
              </div>
              <div className="payment-secured">
                <span>PAYMENTS SECURED BY</span>
                <div className="payment-icons">
                  <img
                    src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png"
                    alt="PayPal"
                  />
                  <img
                    src="https://stripe.com/img/v3/home/twitter.png"
                    alt="Stripe"
                  />
                  <img
                    src="https://cdn.worldvectorlogo.com/logos/visa.svg"
                    alt="Visa"
                  />
                  <img
                    src="https://cdn.worldvectorlogo.com/logos/mastercard-2.svg"
                    alt="MasterCard"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="terms-and-makePayment mt-4">
            <Checkbox onChange={onCheckboxChange}>
              I agree to <a href="#">terms & conditions*</a>
            </Checkbox>
          </div>
          <button
            type="submit"
            className="theme-btn mt-3"
          >
            Make Payment &nbsp;
            <ArrowRightOutlined />
          </button>
        </Form>
      </div>

      <Modal 
      title="Payment kro!!"
      centered
      onCancel={() => setPaymentModalSwitch(false)}
      footer={false}
      open={paymentModalSwitch}
      >
        <h1></h1>
      </Modal>
      <Modal
        title="Design Gallery"
        width="800px"
        open={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <GallerySlider images={sliderImages}/>
      </Modal>
    </>
  );
};

export default CommercialStagingForm;
