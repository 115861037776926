import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import CaterIndustry from "../../Sections/CaterIndustry";
import FirstTime from "../../Sections/FirstTime";
import HowItWorks from "../../Sections/HowItWorks";
import ServiceCard from "../../Sections/ServiceCard";
import ServiceFeatures from "../../Sections/ServiceFeatures";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import BottomNav from "../../Layout/BottomNav";
import HomeBannerSlider from "../../Sections/HomeBannerSlider";
import { useEffect } from "react";
import Navbar from "../../Layout/Header";
import WeCanDO from "../../Sections/WeCanDo";

const GraphicDesignPage = (props: any) => {
  // useEffect(() => {
  //   props?.setUserDash(true);
  //   return () => props?.setUserDash(false);
  // }, [props]);

  return (
    <>
      {/* <Navbar static={true} /> */}

      <BottomNav />

      <WeCanDO />

      <div className="blur-background-box">
        <div className="hp-container">
          <div className="page-title pt-5" data-aos="fade-up">
            <h1>Graphic Designing</h1>
            <p>
              Unlock the full potential of your real estate marketing with our
              amazing graphic design services! We're talking logo and branding,
              poster design, vector design, and digital display design—all
              crafted specifically for the real estate world. Imagine stunning
              visuals that make your brands impact-full and captivating . Let’s
              elevate your brand and make waves in the competitive real estate
              market together!
            </p>
          </div>

          <section id="graphic-design-section">
            <h2 className="mb-5 mt-5 pb-5">Services we provide under Graphic Designing</h2>

            <ServiceCard
              imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
              imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
              description="Looking to make a great first impression? Our Business Card Designing service is here to help! We perfect every detail, creating business cards that make a lasting impression, empowering your networking - Whether you prefer a sleek modern or a classic style."
              title="Business Card designing"
              isPlaceOrder={true}
              classes={["mb-5"]}
              key={1}
              pageUrl="/services/business-card-form"
              price="28"
              listItems={[
                <span>
                  <b>Quick turnaround time</b> in <b>24-48 hours</b>
                </span>,
                <span>
                  <b>Rapid Delivery</b> in <b>just 24 hours</b>
                </span>,
                <b>Free Unlimited Revisions</b>,
                <b>Access 24/7 customer support</b>,
              ]}
            />

            <ServiceCard
              imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
              imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
              description="Want to make your real estate brand unforgettable? Our Logo and Branding service creates stunning, professional logos that grab attention and build trust. Boost your credibility, attract more clients, and close deals faster with a powerful, cohesive brand identity!"
              title="Logo and Branding"
              classes={["mb-5"]}
              isPlaceOrder={true}
              key={1}
              pageUrl="/services/object-removal-form"
              price="32"
              listItems={[
                <span>
                  <b>Guaranteed Best Price </b>at <b>$32</b>
                </span>,
                <span>
                  <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
                </span>,
                <span>
                  <b>Rapid Delivery</b> in <b>just 24 hours</b>
                </span>,
                <b>Free Unlimited Revisions</b>,
                <b>Risk-Free Guarantee**: 100% money-back</b>,
              ]}
            />

            <div id="vector-design-gd">
            <ServiceCard
              imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
              imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
              description="Imagine jazzing up your property listings with eye-popping vector designs that make your homes pop off the screen. Our vector design service turns bland images into vibrant, high-resolution masterpieces that grab attention and boost your online visibility. Get ready to wow potential buyers and close deals faster with graphics that speak volumes! "
              title="Vector Design"
              isPlaceOrder={true}
              key={1}
              pageUrl="/services/vector-design-form"
              price="38"
              classes={["mb-5"]}
              listItems={[
                <span>
                  <b>Guaranteed Best Price </b>at <b>$38</b>
                </span>,
                <span>
                  <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
                </span>,
                <span>
                  <b>Rapid Delivery</b> in <b>just 24 hours</b>
                </span>,
                <b>Free Unlimited Revisions</b>,
                <b>Risk-Free Guarantee**: 100% money-back</b>,
              ]}
            />
            </div>

            <ServiceCard
              imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
              imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
              description="Empowers realtors with interactive digital displays that enhance property presentations. By integrating high-quality visuals, virtual tours, and detailed property information, this service helps listings stand out in a competitive market. Perfect for open houses, office lobbies, and window displays, digital displays boost engagement and attract tech-savvy buyers."
              title="Digital Display Design"
              isPlaceOrder={true}
              key={1}
              pageUrl="/services/digital-display-form"
              price="8"
              classes={["mb-4"]}
              listItems={[
                <span>
                  <b>Guaranteed Best Price </b>at <b>$15</b>
                </span>,
                <span>
                  <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
                </span>,
                <span>
                  <b>Rapid Delivery</b> in <b>just 24 hours</b>
                </span>,
                <b>Free Unlimited Revisions</b>,
                <b>Risk-Free Guarantee**: 100% money-back</b>,
              ]}
            />

          </section>
        </div>

        {/* ========== How it works ========== */}
        <HowItWorks />

        <div className="hp-container">
          {/* First time */}
          <div data-aos="fade-up">
            <FirstTime />
          </div>

          {/* Industry we are catering */}
          <div data-aos="fade-up">
            <CaterIndustry />
          </div>
        </div>
      </div>
    </>
  );
};

export default GraphicDesignPage;
