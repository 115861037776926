
const WeCanDO = () => {
    return (
        <>
            <div className="wecando-wrapper" data-aos="fade-up">
            <div className="wecando-container">
            <h2>See what Stagobox can do for you</h2>
            <p>At Stagobox, we specialize in a diverse array of services dedicated to transforming your real estate ventures and design aspirations. Whether you're a homeowner, real estate professional, Real estate photographer, Realtor or designer, our team is here to collaborate with you at every stage of your project.</p>
            <ul data-aos="fade-up">
                <li>Boost buyer Interest</li>
                <li>Accelarate property Sale</li>
                <li>Fill listings, evoke buyer visualization, banish emptiness</li>
                <li>Customization until perfection achieved</li>
                <li>Satisfaction guaranteed.</li>
                <li>Tailor staging to home's style preferences</li>
            </ul>
            </div>
            </div>
        </>
    )
}

export default WeCanDO;