import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

interface StepData {
  title: string;
  description?: string;
}

interface StepComponentProps {
  current: number;
  steps: StepData[];
}

const StepComponent: React.FC<StepComponentProps> = ({ current, steps }) => {
  return (
    <div className="hp-container">
        <Steps labelPlacement='vertical' current={current}>
      {steps?.map((step, index) => (
        <Step key={index} title={step.title} description={""} />
      ))}
    </Steps>
    </div>
  );
};

export default StepComponent;
