const CompareTable = () => {
  return (
    <>
      <div className="compareTable pt-5" data-aos="fade-up">
        <h2>Distinguishing Traits: Let's Compare and Contrast</h2>
       <div className="table-responsive">
       <table>
          <thead>
            <tr>
              <th>Features</th>
              <th>Stagobox</th>
              <th>Others</th>
              <th>Traditional Staging</th>
              <th>AI Virtual Staging</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rush Delivery</td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
            </tr>
            <tr>
              <td>Cost Affordability</td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
            </tr>
            <tr>
              <td>Unlimited free revisions</td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
            </tr>
            <tr>
              <td>Money back gaurantee</td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
            </tr>
            <tr>
              <td>Customization</td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/green-check.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
              <td>
                <img src="/assets/images/red-cross.svg" alt="" />
              </td>
            </tr>
          </tbody>
        </table>
       </div>

        <div className="traits-container">
      <div className="row">
        <div className="col-md-4">
          <div className="traits-box">
            <img src="/assets/images/check-calendar.svg" alt="" />
            <div className="text">
              <span>Save Time</span>
              <p>
                Saves time up to 72 hrs by delivering ready to market, beautifully staged property in just few hours
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="traits-box">
            <img src="/assets/images/save-money-icon.svg" alt="" />
            <div className="text">
              <span>Save Money</span>
              <p>
                Saves realtors or property owners money up to 90% compared to traditional staging cost
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="traits-box">
            <img src="/assets/images/love-calendaar-icon.svg" alt="" />
            <div className="text">
              <span>Convenient & stress free</span>
              <p>
                Reduce staging time by up to 80% and eliminating the need for physical furniture
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

      
    </>
  );
};

export default CompareTable;
