import { Link } from "react-router-dom";

const FirstTime = () => {
    return(
        <div className="hp-container">
            <section className="first-time radius-60-bg-theme text-center" data-aos="fade-up" style={{background: 'var(--theme-deep-green)'}}>
            <h2 className="text-white">First Time Here?</h2>
            <p className="text-white mt-4 mb-5">Your first image is on us! Try us out at no cost and see what we can do for<br /> your listings.</p>
            <div className="text-center">
                <Link to="/try-for-free" className="theme-btn long-btn">Get Free Trial</Link>
            </div>
        </section>
        </div>
    )
}

export default FirstTime;