import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import CaterIndustry from "../../Sections/CaterIndustry";
import FirstTime from "../../Sections/FirstTime";
import HowItWorks from "../../Sections/HowItWorks";
import ServiceCard from "../../Sections/ServiceCard";
import ServiceFeatures from "../../Sections/ServiceFeatures";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import BottomNav from "../../Layout/BottomNav";
import HomeBannerSlider from "../../Sections/HomeBannerSlider";
import { useEffect } from "react";
import Navbar from "../../Layout/Header";
import WeCanDO from "../../Sections/WeCanDo";

const ResidentialPage = (props: any) => {
  // useEffect(() => {
  //   props?.setUserDash(true);
  //   return () => props?.setUserDash(false);
  // }, [props]);

  return (
    <>
      {/* <Navbar static={true} /> */}

      <BottomNav />

      <WeCanDO />

      <div className="blur-background-box">
        <div className="hp-container">
          <div className="page-title pt-5 pb-5 mb-5" data-aos="fade-up">
            <h1>Our Services</h1>
            <p>
              Help your buyers visualize their business setup in a comfy and
              efficient environment by virtually transforming the vacant
              property.
            </p>
          </div>

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="Furnish your empty spaces with our virtual staging service. Realistic, stunning images enable buyers to visualize the full potential of any property, whether it’s an office or any part of your business space."
            title="Virtual Staging"
            isPlaceOrder={false}
            key={1}
            pageUrl="/services/residential/virtual-staging"
            exploreBtn={true}
            listItems={[
              <span>
                <b>Guaranteed Best Prices</b> at <b>$16</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />

          <div className="mt-5 mb-5">
            <ServiceCard
              imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
              imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
              description="Revitalize your empty commercial spaces with high-quality, lifelike images. Highlight possibilities through stunning visuals, creating a compelling narrative. By showcasing your property's unique features, we ensure your listings stand out and lead to faster sales."
              title="Virtual Renovation"
              isPlaceOrder={false}
              key={1}
              pageUrl="/services/residential/virtual-renovation"
              exploreBtn={true}
              listItems={[
                <span>
                  <b>Guaranteed Best Prices starting from $5</b>
                </span>,
                <span>
                  <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
                </span>,
                <span>
                  <b>Rapid Delivery</b> in <b>just 24 hours</b>
                </span>,
                <b>Free Unlimited Revisions</b>,
                <b>Risk-Free Guarantee**: 100% money-back</b>,
              ]}
            />
          </div>

          <div className="page-title pt-5 pb-5" data-aos="fade-up">
            <h1>Other Services</h1>
          </div>

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="2D floor plan service, with and without furniture, to give precise visualizations of property layouts."
            title="Floor Plan"
            isPlaceOrder={true}
            classes={["mb-5"]}
            key={1}
            pageUrl="/services/image-enhancement-form"
            price="10-14"
            listItems={[
              <span>
                <b>Guaranteed Best Price</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="Transforms daytime photos into captivating, twilight-inspired photos, enhancing their visual appeal."
            title="Day to Dusk"
            classes={["mb-5"]}
            isPlaceOrder={true}
            key={1}
            pageUrl="/services/object-removal-form"
            price="5"
            listItems={[
              <span>
                <b>Guaranteed Best Price </b>at <b>$5</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="Object removal service in real estate photography seamlessly erases unwanted elements, enhancing property images for 
a pristine presentation."
            title="Object Removal"
            isPlaceOrder={true}
            key={1}
            pageUrl="/services/commercial/object-removal"
            price="8"
            classes={["mb-5"]}
            listItems={[
              <span>
                <b>Guaranteed Best Price </b>at <b>$8</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="Converting an occupied space into a vacant one can enhance the property's cleanliness and often leads to increased demand."
            title="Image Enhancement"
            isPlaceOrder={true}
            key={1}
            pageUrl="/services/occupied-vacant-form"
            price="15"
            classes={["mb-4"]}
            listItems={[
              <span>
                <b>Guaranteed Best Price </b>at <b>$15</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />

          <ServiceCard
            imageFront={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-186448911_1727639514.jpg`}
            imageBack={`${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-amar-preciado-18644891_1727639541.jpg`}
            description="Converting an occupied space into a vacant one can enhance the property's cleanliness and often leads to increased demand."
            title="Occupied To Vacant"
            isPlaceOrder={true}
            key={1}
            pageUrl="/services/occupied-vacant-form"
            price="15"
            classes={["mb-4"]}
            listItems={[
              <span>
                <b>Guaranteed Best Price </b>at <b>$15</b>
              </span>,
              <span>
                <b>Lightning Fast Turnaround</b> in <b>24-48 hours</b>
              </span>,
              <span>
                <b>Rapid Delivery</b> in <b>just 24 hours</b>
              </span>,
              <b>Free Unlimited Revisions</b>,
              <b>Risk-Free Guarantee**: 100% money-back</b>,
            ]}
          />
        </div>

        {/* ========== How it works ========== */}
        <HowItWorks />

        <div className="hp-container">
          {/* First time */}
          <div data-aos="fade-up">
            <FirstTime />
          </div>

          {/* Industry we are catering */}
          <div data-aos="fade-up">
            <CaterIndustry />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResidentialPage;
