import Marquee from "react-fast-marquee";

const ClientMarquee = () => {
  return (
    <>
      <div className="marquee">
        <h2 className="text-center pt-5 pb-4">
          10,000 real estate agents trust our<br /> virtual staging services
        </h2>

        <Marquee autoFill={true}>
          <div className="p-4">
            <img src="/assets/images/brand1.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand2.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand3.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand4.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand5.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand6.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand7.png" alt="Brand" />
          </div>

          <div className="p-4">
            <img src="/assets/images/brand8.png" alt="Brand" />
          </div>
        </Marquee>

        {/* <div className="marquee-box-one">
          <div className="marquee-content-one">
            <h2 className="marquee-text">
              <img src="/assets/images/brand1.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand2.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand3.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand4.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand5.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand6.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand7.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand8.png" alt="Brand" />
            </h2>

            <h2 className="marquee-text"><img src="/assets/images/brand1.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand2.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand3.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand4.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand5.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand6.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand7.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand8.png" alt="Brand" /></h2>
          </div>
        </div>

        <div className="marquee-box-two">
          <div className="marquee-content-two">
            <h2 className="marquee-text">
              <img src="/assets/images/brand1.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand2.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand3.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand4.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand5.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand6.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand7.png" alt="Brand" />
            </h2>
            <h2 className="marquee-text">
              <img src="/assets/images/brand8.png" alt="Brand" />
            </h2>

            <h2 className="marquee-text"><img src="/assets/images/brand1.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand2.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand3.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand4.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand5.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand6.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand7.png" alt="Brand" /></h2>
            <h2 className="marquee-text"><img src="/assets/images/brand8.png" alt="Brand" /></h2>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ClientMarquee;
