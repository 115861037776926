import { message } from 'antd';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const getToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

// Base configuration for axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface ApiRequestProps {
  url: string;
  method?: Method;
  payload?: any;
  showMessage?: boolean;
}

const useApiRequest = () => {
  const navigate = useNavigate();

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear the token from localStorage
        localStorage.removeItem('accessToken');
        
        // Redirect to the login page
        navigate('/login');

        // Show error message
        message.destroy();
        message.error('Session expired. Please login again.');
      }
      return Promise.reject(error);
    }
  );

  const apiRequest = async ({
    url,
    method = 'GET',
    payload = {},
    showMessage = false,
  }: ApiRequestProps): Promise<any> => {
    const token = getToken();
    if (!token) {
      // Redirect to login page if token is not found
      navigate('/login');
      throw new Error("User not authenticated. Please login.");
    }

    try {
      const config: AxiosRequestConfig = {
        method,
        url,
        ...(method === 'POST' || method === 'PUT' ? { data: payload } : { params: payload }),
      };

      const response = await axiosInstance(config);

      if (showMessage) {
        message.success('Request successful!');
      }

      return response;
    } catch (error: any) {
      if (showMessage) {
        message.error(`Error: ${error.response ? error.response.data.message : error.message}`);
      }
      console.error('API request error:', error);
      throw error;
    }
  };

  return { apiRequest };
};

export default useApiRequest;


export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



// Room types 

export const serviceOptions = [
  { value: "", label: "Select one..." },
  { value: "Bulk ordering", label: "Bulk ordering" },
  { value: "Virtual Staging", label: "Virtual Staging" },
  { value: "Commercial Virtual Staging", label: "Commercial Virtual Staging" },
  { value: "Matterport Virtual Staging", label: "Matterport Virtual Staging" },
  { value: "Virtual Renovation", label: "Virtual Renovation" },
  { value: "Image Enhancement", label: "Image Enhancement" },
  { value: "Object Removal", label: "Object Removal" },
  { value: "Occupied To Vacant", label: "Occupied To Vacant" },
  { value: "Day To Dusk", label: "Day To Dusk" },
  { value: "Floor Plan", label: "Floor Plan" },
  { value: "360 Virtual Tour", label: "360° Virtual Tours" },
  { value: "3D rendering", label: "3D Render" },
  { value: "Single Property Website", label: "Single Property Website" },
  { value: "Other", label: "Other" }
];

export const roomOptions = [
    "Living/Dining/Kitchen",
    "Living/Dining",
    "Family Room",
    "Dining/Kitchen",
    "Dining",
    "Kitchen",
    "Bedroom 1",
    "Bedroom 2",
    "Bedroom 3",
    "Bedroom 4",
    "Primary/Master Bedroom",
    "Study/Office",
    "Closet",
    "Outdoor Living",
    "Sunroom",
    "Outdoor Living/Dining",
    "Bathroom/Ensuite",
    "Rumpus Room",
    "Game Room",
    "Media Room/Theatre Room",
    "Single Room Studio",
    "Nursery Room",
    "Kids Room/Play Room",
    "Yoga/Meditation Room",
    "Gym/Workout Room",
    "Lounge",
    "Other",
  ];


