import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Drawer,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Modal,
  Form,
  Input,
  Image,
  Upload,
  message,
} from "antd";
import {
  ShoppingCartOutlined,
  MenuOutlined,
  UserOutlined,
  LogoutOutlined,
  EditOutlined,
  DashboardOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header-footer.css";
import BottomNav from "./BottomNav";
import useApiRequest from "../../utils/helperFunctions";
import { useForm } from "antd/es/form/Form";

const { Header } = Layout;
const { SubMenu } = Menu;

const Navbar = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo]: any = useState({});
  const [form] = useForm();
  const [profileUrl, setProfileUrl] = useState("/assets/images/dummy-user.jpg");
  const [profileUploaded, setProfileUploaded] = useState(false);

  const token = localStorage.getItem("accessToken");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuItems = [
    {
      key: "1",
      title: "Home",
      link: "/",
    },
    {
      key: "2",
      title: "Services",
      submenu: [
        {
          key: "VS",
          title: "Virtual Staging",
          link: "/services/residential/virtual-staging",
        },
        {
          key: "VR",
          title: "Virtual Renovation",
          link: "/services/residential/virtual-renovation",
        },
        {
          key: "VC",
          title: "Virtual Commercial",
          link: "/services/commercial/virtual-staging",
        },
        {
          key: "PE",
          title: "Photo Editing",
          link: "/services/pe/image-enhancement",
        },
        {
          key: "FP",
          title: "Floor Plan",
          link: "/services/residential/floor-plan",
        },
        {
          key: "GD",
          title: "Graphic Designing",
          link: "/services/graphic-designing",
        },
      ],
    },
    {
      key: "3",
      title: "Resources",
      submenu: [
        { key: "OG", title: "Our Galllery", link: "/gallery" },
        { key: "review", title: "Review", link: "/reviews" },
        { key: "blog", title: "Blog", link: "/blogs" },
      ],
    },
    {
      key: "4",
      title: "Contact",
      submenu: [
        { key: "CU", title: "Contact with us", link: "/contact-us" },
        { key: "BQ", title: "Bulk Quotes", link: "/order-bulk-quotes" },
      ],
    },
    {
      key: "5",
      title: "About",
      submenu: [{ key: "AU", title: "About Us", link: "/about-us" }],
    },
    {
      key: "7",
      icon: <ShoppingCartOutlined />,
      title: "Cart",
      class: "cart-icon-header",
      link: "/cart",
    },
  ];

  const getSelectedKeys = () => {
    for (let item of menuItems) {
      if (item.link === location.pathname) {
        return [item.key];
      }
      if (item.submenu) {
        for (let subItem of item.submenu) {
          if (subItem.link === location.pathname) {
            return [subItem.key, item.key];
          }
        }
      }
    }
    return [];
  };

  const selectedKeys = getSelectedKeys();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  };

  const [scrolled, setScrolled] = useState(false);
  const { apiRequest } = useApiRequest();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        // Adjust this value as needed
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("click", e);
  };

  const handleProfile = async () => {
    try {
      const resp = await apiRequest({
        url: "profile",
        method: "GET",
        showMessage: false,
        payload: {},
      });
      console.log(resp);

      if (resp.status === 200) {
        console.log("===>>>>", resp.data);
        setUserInfo(resp.data);
        form.setFieldsValue(resp.data);
      }
    } catch (errors) {
      console.error("Failed to fetch service list", errors);
    }
  };

  useEffect(() => {
    if (isProfile) {
      handleProfile();
    }
  }, [isProfile]);

  const onFinish = async () => {
    try {
      let payload = form.getFieldsValue();
      
      if(profileUploaded){
        payload.user_avatar = profileUrl.toString();
      }

      const resp = await apiRequest({
        url: "/profile-update",
        method: "PUT",
        showMessage: false,
        payload: payload,
      });
      // console.log(resp);
      if (resp.status === 200) {
        console.log("Profile updated successfully");
        setIsProfile(false);
      }
    } catch (errors) {
      console.error("Failed to update profile", errors);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Profile",
      key: "1",
      icon: <UserOutlined />,
      // onClick: () => navigate('/profile'),
      onClick: () => setIsProfile(true),
    },
    {
      label: "Dashboard",
      key: "2",
      icon: <DashboardOutlined />,
      onClick: () => navigate("/user-dashboard"),
      // onClick: () => setIsProfile(true),
    },
    {
      label: "Logout",
      key: "3",
      onClick: () => handleLogout(),
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleUploadMedia = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetch(`${process.env.REACT_APP_API_URL}/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust the token retrieval as needed
      },
      body: formData,
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  console.log("handleUploadMedia", handleUploadMedia)

  const handleUploadChange = ({ fileList }: any) => {
    console.log("Uploading", fileList);

    if(fileList[0]?.status === "done"){
      setProfileUrl(fileList[0]?.response?.file_url)
      setProfileUploaded(true)
    }else if(fileList[0]?.status === 'error'){
      message.error(`${fileList[0]?.name} file upload failed.`);
    }

  };

  return (
    <>
      <Header
        className={`main-header ${
          props?.bgWhite === true ? "header_scrolled" : ""
        } ${scrolled ? "header_scrolled" : ""} ${
          props?.static && "static-header"
        }`}
      >
        <div className="logo">
          <Link to="/">
            <img src="/white-logo.png" alt="Stagobox" />
          </Link>
        </div>
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={selectedKeys}
          className="main-navbar"
          style={{ flex: 1, minWidth: 0, justifyContent: "right", border: 0 }}
        >
          {menuItems?.map((item) =>
            item.submenu ? (
              <SubMenu
                className="header-child-menu"
                key={item.key}
                title={item.title}
              >
                {item.submenu.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    <Link to={subItem.link}>{subItem.title}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key}>
                <Link to={item.link}>{item.icon}</Link>
                <span className={item.class}>
                  <Link to={item.link}>{item.title}</Link>
                </span>
              </Menu.Item>
            )
          )}
        </Menu>

        {token ? (
          // <Button
          //   onClick={() => handleLogout()}
          //   className="theme-btn"
          //   style={{ lineHeight: 0 }}
          // >
          //   Logout
          // </Button>
          <Space wrap>
            <Dropdown menu={menuProps} placement="bottom">
              <Button
                style={{ background: "transparent", border: 0, padding: 0 }}
              >
                <UserOutlined />
              </Button>
            </Dropdown>
          </Space>
        ) : (
          <Link style={{ color: "var(--primary)" }} to="/login">
            Sign in
          </Link>
        )}

        <MenuOutlined className="menu-icon" onClick={showDrawer} />
      </Header>

      <Drawer
        title="Menu"
        placement="left"
        onClose={onClose}
        open={visible}
        width="60%"
        className="main-drawer"
      >
        <Menu selectedKeys={selectedKeys} mode="inline">
          {menuItems.map((item) =>
            item.submenu ? (
              <SubMenu key={item.key} title={item.title}>
                {item.submenu.map((subItem) => (
                  <Menu.Item key={subItem.key}>
                    <Link onClick={onClose} to={subItem.link}>
                      {subItem.title}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.key}>
                <Link onClick={onClose} to={item.link}>
                  {item.title}
                </Link>
              </Menu.Item>
            )
          )}
        </Menu>
      </Drawer>

      <Modal
        title="Profile Information"
        open={isProfile}
        footer={false}
        onCancel={() => setIsProfile(false)}
        destroyOnClose
      >
        <div className="profile-info-box">
          <EditOutlined
            onClick={() => setIsEditProfile(!isEditProfile)}
            style={{
              color: "var(--primary)",
              fontSize: "26px",
              position: "absolute",
              top: "50px",
              right: "30px",
            }}
          />
          <div className="profile-img mt-4 mb-4">
            <Image
              src={profileUrl}
              alt="User Profile"
            />
            
          </div>
          <div className="profile-details">
          <div className="text-right">
              <Upload
                onChange={handleUploadChange}
                customRequest={({ file, onSuccess, onError }) => {
                  handleUploadMedia(file).then(onSuccess).catch(onError);
                }}
                showUploadList={false}
                maxCount={1}
              >
                <Button disabled={!isEditProfile} icon={<UploadOutlined />}>Update profile picture</Button>
              </Upload>
            </div>
            {/* <h2>User Details</h2> */}
            <Form
              layout="vertical"
              form={form}
              initialValues={userInfo}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Name"
                name="username"
                rules={[
                  { required: true, message: "Please enter your Username." },
                ]}
              >
                <Input
                  placeholder="Enter your name"
                  disabled={!isEditProfile}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please enter your Email." },
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Phone number.",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Phone number must be exactly 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your phone number"
                  disabled={!isEditProfile}
                />
              </Form.Item>
              <Form.Item label="Address" name="address">
                <Input
                  placeholder="Enter your address"
                  disabled={!isEditProfile}
                />
              </Form.Item>
              {/* <Form.Item label="Password">
                  <Input.Password placeholder="Enter your password" />
                </Form.Item> */}
              {/* <Form.Item label="Confirm Password">
                  <Input.Password placeholder="Confirm your password" />
                </Form.Item> */}
              <Form.Item>
                <button type="submit" className="theme-btn w-100 mt-4">
                  Update Profile
                </button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Navbar;
