import CommercialStagingForm from "./CommercialStagingForm"

const DigitalDisplayForm = () => {
    return (
        <>
            <CommercialStagingForm />
        </>
    )
}

export default DigitalDisplayForm;